import React from "react"
export const Nav31DataSource = {
  wrapper: { className: "header3 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo",
    children: "https://deephire.s3.amazonaws.com/logos/Horizontal.svg",
  },
  Menu: {
    className: "header3-menu",
    children: [
      {
        name: "item~kf6zw8yhaem",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Product</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: [
          {
            name: "sub1",
            className: "item-sub",
            children: {
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8wnt2acf-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/outlined/laptop.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>Pre-Recorded Interviews</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content kf6zxf5d6eo-editor_css",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>Have candidates record video</p>
                                    <p>introductions</p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/one-way-interviews",
              target: "",
            },
          },
          // {
          //   name: "sub~kcz008mmgsc",
          //   className: "item-sub",
          //   children: {
          //     className: "item-sub-item kcz8vuom79d-editor_css",
          //     children: [
          //       {
          //         name: "image0",
          //         className: "item-image kcz8vvjskoi-editor_css",
          //         children:
          //           "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/video-camera.svg",
          //       },
          //       {
          //         name: "title",
          //         className: "item-title",
          //         children: (
          //           <span>
          //             <span>
          //               <span>
          //                 <p>Live Interviews</p>
          //               </span>
          //             </span>
          //           </span>
          //         ),
          //       },
          //       {
          //         name: "content",
          //         className: "item-content kf6zxy8kbqe-editor_css",
          //         children: (
          //           <span>
          //             <span>
          //               <span>
          //                 <span>
          //                   <span>
          //                     <span>
          //                       <p>Two way interviews built</p>
          //                       <p>for recruiters&nbsp;</p>
          //                     </span>
          //                   </span>
          //                 </span>
          //               </span>
          //             </span>
          //           </span>
          //         ),
          //       },
          //     ],
          //     href: "/live-interviews",
          //     target: "",
          //   },
          // },
          {
            name: "sub~kf6wcsf5a6",
            className: "item-sub",
            children: {
              className: "item-sub-item kcz8vuom79d-editor_css",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8vvjskoi-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/shop.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <p>What is DeepHire?</p>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content kf6zzz6t2aa-editor_css",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Best in class video interview</p>
                              <p>provider</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/what-is-deephire",
              target: "",
            },
          },
        ],
      },
      {
        name: "item~kf70om9czqi",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Solutions</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: [
          {
            name: "sub1",
            className: "item-sub",
            children: {
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8wnt2acf-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/outlined/laptop.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Pre Hiring Screening</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content kf6zxf5d6eo-editor_css",
                  children: (
                    <span>
                      <span>
                        <p>Find &amp; hire the best talent</p>
                        <p>faster</p>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/pre-screening",
              target: "",
            },
          },
          // {
          //   name: "sub~kcz008mmgsc",
          //   className: "item-sub",
          //   children: {
          //     className: "item-sub-item kcz8vuom79d-editor_css",
          //     children: [
          //       {
          //         name: "image0",
          //         className: "item-image kcz8vvjskoi-editor_css",
          //         children:
          //           "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/video-camera.svg",
          //       },
          //       {
          //         name: "title",
          //         className: "item-title",
          //         children: (
          //           <span>
          //             <span>
          //               <span>
          //                 <span>
          //                   <p>Live Interviewing</p>
          //                 </span>
          //               </span>
          //             </span>
          //           </span>
          //         ),
          //       },
          //       {
          //         name: "content",
          //         className: "item-content kf6zxy8kbqe-editor_css",
          //         children: (
          //           <span>
          //             <span>
          //               <span>
          //                 <span>
          //                   <span>
          //                     <p>Two way interviews built</p>
          //                     <p>for recruiters&nbsp;</p>
          //                   </span>
          //                 </span>
          //               </span>
          //             </span>
          //           </span>
          //         ),
          //       },
          //     ],
          //     href: "/live-interviewing",
          //     target: "",
          //   },
          // },
          {
            name: "sub~kf6wcsf5a6",
            className: "item-sub",
            children: {
              className: "item-sub-item kcz8vuom79d-editor_css",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8vvjskoi-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/outlined/team.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <p>Collaborative Team Hiring</p>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content kf6zzz6t2aa-editor_css",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>Share videos of your candidates</p>
                                <p>with teammates &amp; clients</p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/team-hiring",
              target: "",
            },
          },
          {
            name: "sub~kf70qzvfct",
            className: "item-sub",
            children: {
              className: "item-sub-item kcz8vuom79d-editor_css",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8vvjskoi-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/outlined/share-alt.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <p>Send Out Interviews</p>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content kf6zzz6t2aa-editor_css",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>See what your clients ask</p>
                                  <p>in their interviews</p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/send-outs",
              target: "",
            },
          },
        ],
      },
      {
        name: "item~keu6tmfv74a",
        className: "header3-item",
        children: {
          href: "#",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Resources</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: [
          {
            name: "sub1",
            className: "item-sub",
            children: {
              className: "item-sub-item",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8wnt2acf-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/book.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <p>Blog</p>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content",
                  children: (
                    <span>
                      <p>Best Practices</p>
                    </span>
                  ),
                },
              ],
              href: "https://blog.deephire.com",
              target: "_blank",
            },
          },
          {
            name: "sub~kcz008mmgsc",
            className: "item-sub",
            children: {
              className: "item-sub-item kcz8vuom79d-editor_css",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8vvjskoi-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/sound.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <p>Podcast</p>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content",
                  children: (
                    <span>
                      <span>
                        <p>Top Recruiters</p>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "https://anchor.fm/deephire-podcast",
              target: "_blank",
            },
          },
          {
            name: "sub~kf6wcsf5a6",
            className: "item-sub",
            children: {
              className: "item-sub-item kcz8vuom79d-editor_css",
              children: [
                {
                  name: "image0",
                  className: "item-image kcz8vvjskoi-editor_css",
                  children:
                    "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/video-camera.svg",
                },
                {
                  name: "title",
                  className: "item-title",
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      DeepHire <br />
                                      vs Zoom
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: "content",
                  className: "item-content",
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>Comparison</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
              href: "/zoom",
              target: "",
            },
          },
        ],
      },
      {
        name: "item1",
        className: "header3-item",
        children: {
          href: "/pricing",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Pricing</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: null,
      },
      {
        name: "item~kfoamxr1et",
        className: "header3-item",
        children: {
          href: "https://recruiter.deephire.com",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Login</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: null,
      },
      {
        name: "item~kfoamwdf9io",
        className: "header3-item",
        children: {
          href: " ",
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Start Free</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: "text",
            },
          ],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: "header3-mobile-menu" },
}
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5 kctityxly1r-editor_css" },
  page: { className: "home-page banner5-page kcz8vxyiuvj-editor_css" },
  childWrapper: {
    className: "banner5-title-wrapper kcxzzfruow-editor_css",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Ultimate Pre-Screen Video Interview Platform</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: "banner5-title kd5z81k44h-editor_css",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <font>
                              <font>
                                <font>
                                  <font>
                                    Streamline your hiring process and identify
                                    top talent before the first conversation.
                                  </font>
                                </font>
                              </font>
                            </font>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "image~kfoa5lhe59",
        className: "kfoa5ojswqb-editor_css",
        children: "https://deephire.s3.amazonaws.com/websiteAssets/checks2.png",
      },
      {
        name: "content",
        className: "banner5-content kfoa66zssyk-editor_css",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Qualify Candidates Faster</p>
                          <p>
                            <span>Provide a Great Candidate Experience</span>
                          </p>
                          <p>
                            <span>Reduce Time to Hire</span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
          className: "banner5-button kd5z8m3n24l-editor_css",
          type: "primary",
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <font>
                      <font>Start Free Now</font>
                    </font>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: "banner5-image kcxwzhfpex-editor_css",
    children: "https://deephire.s3.amazonaws.com/image.png",
  },
}
export const Feature40DataSource = {
  wrapper: { className: "home-page-wrapper content6-wrapper" },
  OverPack: { className: "home-page content6" },
  textWrapper: { className: "content6-text", xs: 24, md: 10 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>Streamline Every Step in Your Interviewing Process.&nbsp;</p>
            </span>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_informed_decision_p2lh.svg",
    className: "content6-img",
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: "block0",
        img: {
          children:
            "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          className: "content6-icon",
        },
        title: {
          className: "content6-title",
          children: (
            <span>
              <p>Make every recruiter consistent and scalable.&nbsp;</p>
            </span>
          ),
        },
        content: {
          className: "content6-content",
          children: (
            <span>
              <p>
                A single platform to drive efficiency and effectiveness for
                Sourcers, Recruiters, Screeners, Account Managers, and Hiring
                Authorities.&nbsp;
              </p>
            </span>
          ),
        },
      },
      {
        name: "block1",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
        },
        title: {
          className: "content6-title",
          children: (
            <span>
              <p>Optimize your process to fill more roles faster.&nbsp;</p>
            </span>
          ),
        },
        content: {
          className: "content6-content",
          children: (
            <span>
              <p>
                Collaborate with Hiring Authorities to ensure the best
                candidates move forward at every step.&nbsp;
              </p>
            </span>
          ),
        },
      },
      {
        name: "block2",
        img: {
          className: "content6-icon",
          children:
            "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
        },
        title: {
          className: "content6-title",
          children: (
            <span>
              <span>
                <span>
                  <p>
                    Stand out from the competition and win more roles.&nbsp;
                  </p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: "content6-content",
          children: (
            <span>
              <span>
                <p>
                  Use a clean, simple, state of the art interviewing technology
                  to provide the perfect hiring experience.&nbsp;
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
}
export const Content122DataSource = {
  wrapper: {
    className: "home-page-wrapper content12-wrapper kf5okpqplr-editor_css",
  },
  page: { className: "home-page content12" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <p>Join the Incredible Organizations using DeepHire</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  block: {
    className: "img-wrapper",
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/1.png",
          },
        },
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/2.png",
          },
        },
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/3.png",
          },
        },
      },
      {
        name: "block3",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/4.png",
          },
        },
      },
      {
        name: "block4",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/5.png",
          },
        },
      },
      {
        name: "block5",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/customerLogos/6.png",
          },
        },
      },
    ],
  },
}
export const Content50DataSource = {
  wrapper: {
    className: "home-page-wrapper content5-wrapper kf6zqvhrl0j-editor_css",
  },
  page: { className: "home-page content5 kf6v0pzjq9r-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Resources to Support You Along the Way</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      // {
      //   name: "block2",
      //   className: "block",
      //   md: 12,
      //   xs: 24,
      //   children: {
      //     wrapper: {
      //       className: "content5-block-content kf5qf8iei1-editor_css",
      //       href: "/live-interviews",
      //     },
      //     img: {
      //       children:
      //         "https://www.hubspot.com/hubfs/Blog_Move_On_Up/Videointerview.png",
      //       className: "kf37jxidshs-editor_css",
      //     },
      //     content: {
      //       children: (
      //         <span>
      //           <span>
      //             <span>
      //               <span>
      //                 <span>
      //                   <p>What are: Live Interviews</p>
      //                 </span>
      //               </span>
      //             </span>
      //           </span>
      //         </span>
      //       ),
      //     },
      //   },
      // },
      {
        name: "block3",
        className: "block",
        md: 24,
        xs: 24,
        children: {
          wrapper: {
            className: "content5-block-content",
            href: "/one-way-interviews",
          },
          img: {
            children:
              "https://www.springboard.com/blog/wp-content/uploads/2020/04/SB-Blog-Tipsvideointerview-A-final-768x314.png",
            className: "kf5q9not3x-editor_css",
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          What are Pre-Recorded Interviews?
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      // {
      //   name: "block5",
      //   className: "block kf6vx0s0zi-editor_css",
      //   md: 24,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: "content5-block-content", href: "/zoom" },
      //     img: {
      //       children:
      //         "https://deephire.s3.amazonaws.com/websiteAssets/Stop+using+Zoom+for+Video+Interviews.+(4).png",
      //       className: "kf5qiyz2uw-editor_css",
      //     },
      //     content: {
      //       children: (
      //         <span>
      //           <span>
      //             <span>
      //               <span>
      //                 <p>Comparison: Why DeepHire vs Zoom?</p>
      //               </span>
      //             </span>
      //           </span>
      //         </span>
      //       ),
      //     },
      //   },
      // },
    ],
  },
}
export const Feature60DataSource = {
  wrapper: { className: "home-page-wrapper feature6-wrapper" },
  OverPack: { className: "home-page feature6", playScale: 0.3 },
  Carousel: {
    className: "feature6-content",
    dots: false,
    wrapper: { className: "feature6-content-wrapper" },
    titleWrapper: {
      className: "feature6-title-wrapper",
      barWrapper: {
        className: "feature6-title-bar-wrapper",
        children: { className: "feature6-title-bar" },
      },
      title: { className: "feature6-title" },
    },
    children: [
      {
        title: {
          className: "feature6-title-text",
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>DeepHire Stats</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        className: "feature6-item",
        name: "block0",
        children: [
          {
            md: 8,
            xs: 24,
            className: "feature6-number-wrapper",
            name: "child0",
            number: {
              className: "feature6-number",
              unit: {
                className: "feature6-unit kctk3wja3dt-editor_css",
                children: (
                  <span>
                    <p>+</p>
                  </span>
                ),
              },
              toText: true,
              children: "1000",
            },
            children: {
              className: "feature6-text",
              children: (
                <span>
                  <span>
                    <p>hours saved</p>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: "feature6-number-wrapper",
            name: "child1",
            number: {
              className: "feature6-number",
              unit: {
                className: "feature6-unit kctjzr0p3ul-editor_css",
                children: (
                  <span>
                    <p>+</p>
                  </span>
                ),
              },
              toText: true,
              children: "10,000",
            },
            children: {
              className: "feature6-text",
              children: (
                <span>
                  <p>candidate interviews</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: "feature6-number-wrapper",
            name: "child2",
            number: {
              className: "feature6-number",
              unit: {
                className: "feature6-unit kctk0pwgf1p-editor_css",
                children: (
                  <span>
                    <p>%</p>
                  </span>
                ),
              },
              toText: true,
              children: "97",
            },
            children: {
              className: "feature6-text",
              children: (
                <span>
                  <p>candidate satisfaction rate</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper kf6u7dxr6de-editor_css",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>Ready to Become More Effective?&nbsp;</p>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <p>
                DeepHire is more than just software - with our Video
                Interviewing platform, you get a playbook for success.&nbsp;
              </p>
            </span>
          </span>
        ),
        className: "title-content",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <span>
              <p>Get Started</p>
            </span>
          </span>
        ),
      },
    },
  },
}
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          className: "logo",
          children:
            "https://deephire.s3.amazonaws.com/logos/Horizontal+White.svg",
        },
        childWrapper: {
          className: "slogan",
          children: [
            {
              name: "content0",
              children: (
                <span>
                  <p>
                    Premium video interview provider. We look forward to working
                    with you!
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          children: (
            <span>
              <span>
                <p>Product</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: "link2",
              href: "/what-is-deephire",
              children: (
                <span>
                  <span>
                    <span>
                      <p>What is DeepHire?&nbsp;</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "link0",
              href: "/pricing",
              children: (
                <span>
                  <span>
                    <p>Pricing</p>
                  </span>
                </span>
              ),
              className: "kf6vq0gfksm-editor_css",
            },
          ],
        },
      },
      {
        name: "block~kf38bitbqdj",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          children: (
            <span>
              <span>
                <span>
                  <p>Solutions</p>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: "content~kf38mkg8pam",
              className: "kf6vie82ghl-editor_css",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <a href="/pre-screening">Pre Hiring Screening</a>
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },

            {
              name: "content~kf38n1vkj2m",
              className: "kf6vj360jdd-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      <a href="/team-hiring">Collaborative Team Hiring</a>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: "content~kf38n2uj5g",
              className: "kf6vj4q3m3-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      <a href="/send-outs">Send Out Interviews</a>
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block~kf38dxerea6",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Contact</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
          className: "kf4eusqapam-editor_css",
        },
        childWrapper: {
          children: [
            {
              name: "content~kf38ev4tui",
              className: "",
              children: (
                <span>
                  <span>
                    <span>
                      <p>526 S. Main Street Suite 511</p>
                      <p>Akron, Ohio 44311</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content~kf38gxoxya",
              className: "",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <br />
                          </p>
                          <p>
                            <a href="tel:13309318770">(330) 931 8770</a>
                          </p>
                          <p>
                            <br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "button~kf38hft3fk",
              className: "",
              children: {
                children: (
                  <span>
                    <span>
                      <p>Sign In</p>
                    </span>
                  </span>
                ),
                href: "https://recruiter.deephire.com",
                type: "default",
                className: "ant-btn-default kf6ztalhf8c-editor_css",
              },
            },
          ],
        },
      },
      {
        name: "block2",
        xs: 24,
        md: 6,
        className: "block",
        title: {
          children: (
            <span>
              <span>
                <p>Resources</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: "content~kf38j0xkr3h",
              className: "kf6vjz8s93-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      <a href="https://blog.deephire.com">Blog</a>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: "content~kf38jut9gwg",
              className: "kf6vmdoat6s-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      <a href="/zoom">Comparison: Zoom vs DeepHire</a>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: "content~kf38jwvv5g6",
              className: "kf6vmft01i-editor_css",
              children: (
                <span>
                  <span>
                    <p>
                      <a href="https://help.deephire.com">Support Centre</a>
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        ©{new Date().getFullYear()} by{" "}
        <a href="https://deephire.com">DeepHire</a>&nbsp;All Rights Reserved
      </span>
    ),
  },
}
